.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F58092; /* Updated pink color */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    width: 100%;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
    padding: 3px 20px;
}

.header .search-bar {
    flex: 1;
    margin: 0 0px;
}

.logo {
    font-size: 36px;
    color: white;
    margin-right: 30px; 
    text-decoration: none; /* Remove underline */
}

.nav {
    display: flex;
    align-items: center;
}

.nav a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
}

.nav a:hover {
    text-decoration: underline;
}

.login-icon {
    width: 24px; /* Adjust size as needed */
    height: 24px;
    margin-left: 20px; /* Add space between the last nav item and the login icon */
}

/* Hide the mobile logo by default */
.mobile-logo {
    display: none;
}

/* Default styles for larger screens */
.full-logo {
    display: inline; /* Ensure full logo is visible */
}

.nav {
    display: flex; /* Ensure navigation is visible */
}

.login-icon {
    display: inline; /* Ensure login icon is visible */
}

/* Media query for mobile screens */
@media (max-width: 600px) {
    .full-logo {
        display: none; /* Hide full "gradies" logo */
    }

    .mobile-logo {
        display: inline; /* Show the "g" logo */
        font-size: 36px; /* Ensure visibility on smaller screens */
        margin-right: 10px; /* Adjust spacing for alignment */
    }

    .nav {
        display: none; /* Hide navigation items on mobile */
    }

    .login-icon {
        display: none; /* Hide the login icon on mobile */
    }

    .header .search-bar {
        margin-left: 10px; /* Adjust margin to ensure full visibility */
        margin-right: 10px;
        width: calc(100% - 40px); /* Ensure the search bar takes up the full width available */
    }
}
