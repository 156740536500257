/* AdminSidebar.css */

.admin-sidebar {
    width: 250px;
    background-color: #F58092; /* Gradies pink */
    color: white;
    padding: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.admin-sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.admin-sidebar li {
    padding: 10px 0;
    cursor: pointer;
    transition: background-color 0.3s;
}

.admin-sidebar li:hover {
    background-color: #e05682;
}
