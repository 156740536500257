.admin-content {
    padding: 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.admin-content h2 {
    margin-bottom: 20px;
}

.search-bar {
    display: flex;
    margin-bottom: 20px;
}

.search-bar input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.insert-listing-button {
    background-color: #F58092; /* Gradies pink */
    color: white;
    border: none;
    padding: 2px 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.delete-listing-container {
    margin-top: 20px; /* Adjust as needed */
    display: flex;
    gap: 10px;
    align-items: center;
}

.cert-number-input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 300px; /* Adjust width as needed */
}

.delete-listing-button {
    background-color: #F58092; /* Gradies pink */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.delete-listing-button:hover {
    background-color: #e06675; /* Darken the color on hover */
}

.insert-listing-button:hover {
    background-color: #e06675; /* Darken the pink on hover */
}

.search-bar button {
    padding: 10px;
    background-color: #f58092;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
}

.search-bar button:hover {
    background-color: #e06675;
}

.listings-table,
.certs-table,
.cards-table,
.orders-table,
.sets-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.listings-table th, .listings-table td,
.certs-table th, .certs-table td,
.cards-table th, .cards-table td,
.orders-table th, .orders-table td,
.sets-table th, .sets-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.listings-table th, .certs-table th,
.cards-table th, .orders-table th,
.sets-table th {
    background-color: #f4f4f9;
    color: #333;
}

.load-more {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #f58092;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.load-more:hover {
    background-color: #e06675;
}

.cert-images {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.cert-image {
    max-width: 500px; /* Adjust this size as needed */
    max-height: 500px; /* Adjust this size as needed */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
