/* AdminDashboard.css */

.admin-dashboard {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #f4f4f9;
}

.admin-main {
    display: flex;
    flex: 1;
    height: 100%;
}
