/* BrokerDashboard.css */

.broker-dashboard {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #f4f4f9;
}

.broker-main {
    display: flex;
    flex: 1;
    height: 100%;
}
