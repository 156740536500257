.orders-page {
    padding: 20px;
}

.orders-table {
    width: 100%;
    border-collapse: collapse;
}

.orders-table th, .orders-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

.orders-table .highlight-red {
    background-color: #ffcccc; /* Light red for unconfirmed orders */
}

.orders-table .highlight-yellow {
    background-color: #fff5cc; /* Light yellow for confirmed but not fulfilled orders */
}

.orders-table .highlight-green {
    background-color: #ccffcc; /* Light green for fulfilled orders */
}

.confirm-button, .fulfill-button {
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
}

.confirm-button:disabled, .fulfill-button:disabled {
    cursor: not-allowed;
    background-color: #ccc;
}

.tracking-input {
    margin: 0 5px;
    padding: 5px;
    border: 1px solid #ccc;
    width: 150px;
}
