/* BrokerContent.css */

.broker-content {
    padding: 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.broker-content h2 {
    margin-bottom: 20px;
}

/* PSA Order Button */
.psa-order-button {
    background-color: #F58092; /* Gradies pink */
    color: white;
    padding: 20px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.psa-order-button:hover {
    background-color: #e05682; /* Slightly darker pink */
}

.psa-order-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px #f5a4b2; /* Focus outline */
}

.psa-orders-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.psa-orders-table th,
.psa-orders-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.psa-orders-table th {
    background-color: #f4f4f9;
    color: #333;
}

.broker-content button {
    background-color: #F58092; /* Gradies pink */
    color: white; /* White text */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.broker-content button:hover {
    background-color: #ff7082; /* Slightly darker pink on hover */
}

.broker-content button:focus {
    outline: none;
}

.total-value {
    font-weight: bold;
    font-size: 18px;
    color: #333;
    text-align: right;
}

.cert-image {
    width: 50px; /* Small thumbnail size */
    height: auto;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.cert-image:hover {
    transform: scale(2); /* Enlarge image on hover */
}

/* Hidden modal overlay that becomes visible on click */
.image-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */
    display: none; /* Hidden by default */
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Large image inside modal */
.image-modal-content {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain; /* Ensures the image fits within the modal */
}

/* Show the modal overlay when active */
.show-modal {
    display: flex; /* Flexbox to center the image */
}

/* Add bold styling for matched price */
.bold-price {
    font-weight: bold;
    color: #F58092; /* Gradies pink for emphasis */
}

/* Style for the price list */
.price-charting-prices div {
    margin: 2px 0; /* Add spacing between prices */
}

.price-input {
    width: 80px; /* Adjust the width as needed */
    padding: 5px; /* Optional: Reduce padding for a smaller look */
    font-size: 14px; /* Adjust font size for better fit */
    border: 1px solid #ddd; /* Match table styling */
    border-radius: 4px; /* Optional: Add rounded corners */
}

.certs-table tr.disabled,
.broker-listings-table tr.disabled {
    opacity: 0.5;
    pointer-events: none;
}
