/* Dark semi-opaque overlay that covers the entire screen */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Semi-opaque background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* High z-index to appear above all other elements */
}

/* Centered modal with glassy effect */
.post-purchase-modal {
    background: rgba(255, 255, 255, 0.9);
    padding: 2rem;
    border-radius: 15px;
    max-width: 400px;
    text-align: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(8px); /* Glass effect */
    animation: fadeIn 0.3s ease-out;
}

/* Title and message styling */
.post-purchase-modal h2 {
    color: #F58092; /* Matching Gradies pink */
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.post-purchase-modal p {
    color: #333;
    margin-bottom: 2rem;
}

/* Button styling */
.post-purchase-modal button {
    background-color: #F58092; /* Matching Gradies pink */
    color: white;
    border: none;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.post-purchase-modal button:hover {
    background-color: #e0667c;
}


/* Fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
