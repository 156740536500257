/* HomePage.css */

.header .search-bar input {
    width: 100%;
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 50px;
    outline: none;
}

.load-more-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%; /* Ensure the container spans the full width */
}

.load-more {
    padding: 5px 10px;
    background-color: #f58092; /* Gradies pink */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
    width: 100px; /* Set a fixed width for the button */
}

.load-more:hover {
    background-color: #e06675;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.gradies-banner {
    background-color: #fff;
    color: #F58092; /* Gradies pink */
    height: 200px; /* Adjust to match the height of a card */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px; /* Space between banner and featured binder */
    position: relative; /* Needed for line placement */

}

.gradies-banner p:first-child {
    font-size: 5rem;
    margin: 0;
}

.gradies-banner p:last-child {
    font-size: 1.5rem;
    margin: 5px 0 0;
}

.see-more-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #f58092; /* Gradies pink color */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.see-more-button:hover {
    background-color: #d4697e; /* Slightly darker pink */
}
