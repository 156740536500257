/* Reset default margins and paddings */
:root {
  --primary-color: #F58092; /* Your preferred pink color */
  --secondary-color: #AAAAAA; /* Light grey from the card */
}
html, body {
  margin-top: 40px; /* Adjust based on header height */
  padding: 20px;
  width: 100%;
  height: 100%;
  font-family: 'Arial', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.nav {
  display: flex;
  gap: 20px;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #FFFFFF; /* Adjust as needed */
  padding: 10px 20px;
}

button:hover {
  text-decoration: underline;
  color: #FFFFFF; /* Use primary color */
}

.fa-user-circle {
  font-size: 24px;
  cursor: pointer;
  color: #333; /* Adjust as needed */
  padding: 10px 20px;
}

.fa-user-circle:hover {
  color: var(--primary-color); /* Use primary color */
}