.order-detail {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
    gap: 2rem;
    flex-wrap: wrap;
}

.order-info, .payment-info {
    flex: 1;
    max-width: 600px;
    background: rgba(255, 255, 255, 0.15);
    padding: 1.5rem;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
}

.order-info h1 {
    font-size: 1.8rem;
    color: #333;
}

.order-info h3, .payment-info h3 {
    font-size: 1.3rem;
    color: #444;
}

.price-details {
    margin-top: 1rem;
}

.price-details p, .price-details .total {
    display: flex;
    justify-content: space-between;
    font-size: 1.1rem;
    color: #333;
}

.shipping-address, .payment-info {
    margin-top: 1rem;
}

.shipping-address p, .payment-info p {
    font-size: 1rem;
    color: #555;
}

/* Style for the image container on the right */
.order-image {
    flex: 1;
    max-width: 300px; /* Adjust as necessary */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
}

.order-image img {
    width: 100%;
    max-width: 300px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Ensure modal content in .order-info section doesn’t interfere with layout */
.order-info .order-actions-modal {
    margin-top: 1.5rem;
    width: 100%;
    max-width: 400px;
    align-self: flex-start; /* Align to top of content */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .order-detail {
        flex-direction: column;
        align-items: center;
    }
    .order-info, .payment-info, .order-image {
        max-width: 100%;
    }
}
