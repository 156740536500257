/* Container styled with 8-bit look for inline panel */
.order-actions-modal {
    background-color: #f8f8f8;
    border: 2px solid #000;
    padding: 1.5rem;
    border-radius: 5px;
    box-shadow: inset 0 0 10px #000;
    width: 100%;
    max-width: 400px;
    text-align: center;
    font-family: 'Press Start 2P', cursive;
    margin-top: 2rem; /* Increased space above panel to separate from price details */
}

/* Header text styling */
.modal-header {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 1rem;
}

.action-buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
}

/* Confirm button styling */
.confirm-button {
    background-color: #F58092;
    color: white;
    border: none;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    max-width: 200px;
}

.confirm-button:hover {
    background-color: #e0667c;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Tracking section with input below fulfill button */
.tracking-section {
    display: flex;
    flex-direction: column; /* Stack vertically */
    align-items: center;
    width: 100%;
    max-width: 200px;
    margin-top: 1.5rem; /* Space above section */
}

/* Adjust fulfill button to appear above tracking input */
.tracking-section .fulfill-button {
    background-color: #F58092;
    color: white;
    border: none;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    order: -1; /* Move button above input */
}

/* Hover effect for active buttons */
.confirm-button:hover:not([disabled]),
.fulfill-button:hover:not([disabled]) {
    background-color: #e0667c;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.tracking-input {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    width: 100%;
    margin-top: 0.5rem; /* Space between input and button */
}

/* Price details styling for additional spacing */
.price-details {
    margin-bottom: 2rem; /* Extra space below price details */
}

/* Disabled button styling */
.confirm-button:disabled,
.fulfill-button:disabled {
    background-color: #d3d3d3; /* Gray color for disabled state */
    color: #888888; /* Lighter text color for disabled state */
    cursor: not-allowed; /* Change cursor to indicate non-clickable */
    box-shadow: none; /* Remove shadow effect */
}