/* ListingDetail.css */

/* Default styles for larger screens (desktop/tablet) */
.listing-detail {
    display: flex;
    justify-content: space-between;
    padding-top: 2.5%;
    padding-bottom: 40px;
    max-width: 85%;
    margin: 0 auto;
    gap: 80px;
}

.listing-info {
    flex: 1;
    align-items: center;
    transform: scale(1.1); /* Slightly reduced scale */
}

.name-grade {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.name-grade h1 {
    font-size: clamp(1.5rem, 5vw, 2.5rem); /* Dynamically adjust the name size */
    margin: 0;
    font-style: italic;
}

.grade {
    font-size: clamp(1.5rem, 5vw, 2.5rem); /* Dynamically adjust the grade size */
    font-weight: bold;
}

.details-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;
}

.card-details {
    flex: 1; /* Takes up the left side */
    display: flex;
    flex-direction: column; /* Stack details vertically */
    align-items: flex-start; /* Align content to the left */
    gap: 5px; /* Reduced spacing for closer vertical alignment */
    line-height: 1.2; /* Adjusted line spacing */
}

.card-details p {
    font-style: italic;
    margin: 0; /* Remove margin for tighter spacing */
}

.illustrator {
    font-weight: bold;
    margin-top: auto; /* Push the illustrator to the bottom */
}

.pricecharting-container {
    flex-shrink: 0; /* Ensures the widget doesn't stretch */
    display: flex;
    justify-content: flex-end; /* Align the widget to the right */
}

.pricecharting-widget {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
    text-align: left; /* Align text to the left */
    transition: background-color 0.2s ease-in-out;
}

.pricecharting-widget:hover {
    background-color: #eaeaea;
}

.pricecharting-widget h3 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #333;
    text-align: center; /* Center the header */
}

.pricecharting-widget .price-row {
    display: grid;
    grid-template-columns: 55px 15px auto; /* Adjust width of colon column */
    gap: 5px;
    align-items: center; /* Vertically center all items */
    margin: 3px 0; /* Adjust spacing between rows */
}

.pricecharting-widget .price-row .label {
    text-align: left; /* Left-align "PSA X" */
}

.pricecharting-widget .price-row .colon {
    text-align: center; /* Center-align the colon */
    line-height: 1; /* Ensure the colon sits properly in the middle */
    font-size: 1rem; /* Match font size */
}

.pricecharting-widget .price-row .price {
    text-align: right; /* Right-align the price */
}

.pricecharting-widget p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
    display: flex;
    justify-content: space-between;
    gap: 10px; /* Adds space between text and value */
    text-align: left;
}

.pricecharting-widget p span {
    flex-shrink: 0; /* Prevents the price from wrapping */
    margin-left: auto; /* Align prices to the right */
}

/* Tiny, bold date at the bottom */
.pricecharting-widget .date-updated {
    font-size: 10px; /* Tiny font */
    font-weight: bold; /* Bold text */
    text-align: center; /* Align text to the right */
    margin-top: 8px; /* Spacing from content above */
    color: #000000; /* Subtle gray color */
}

.listing-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1.1);
}

.listing-image img {
    max-width: 100%;
    height: auto;
    border-radius: 25px; 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.glass-pane {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(15px);
    border-radius: 25px;
    padding: 40px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%);
}

.flavor-text-box {
    position: relative;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px;
    border-radius: 10px;
    margin-top: 20px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.2rem;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%);
}

.price-details p {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
}

.price-details .total {
    font-size: 1.2rem;
    font-weight: bold;
}
@media (max-width: 600px) {
    .listing-detail {
        display: flex;
        flex-direction: column; /* Stack everything vertically */
        justify-content: flex-start;
        align-items: stretch; /* Stretch elements to fill the width */
        padding: 2.5% 5%;
        max-width: 100%;
        margin: 0 auto;
        gap: 20px;
    }

    .listing-info {
        width: 100%; /* Ensure full width */
        max-height: none;
        position: static;
        color: black;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 25px;
        overflow: hidden; /* Prevent overlapping issues */
        padding: 20px;
        box-sizing: border-box; /* Include padding in width calculations */
    }

    .name-grade {
        display: flex;
        justify-content: space-between; /* Align title and grade horizontally */
        align-items: baseline; /* Align bottom of grade with card name */
        gap: 10px;
        width: 100%;
        margin-bottom: 5px; /* Add spacing for separation */
    }

    .name-grade h1 {
        font-size: clamp(1.4rem, 4vw, 2.5rem); /* Dynamically scale the card name */
        font-weight: bold;
        margin: 0; /* Remove any margin */
        overflow-wrap: break-word; /* Allow wrapping on long names */
        word-wrap: break-word; /* Legacy support for wrapping */
        word-break: break-word; /* Break long words if necessary */
        flex: 1; /* Allow name to shrink dynamically */
    }

    .grade {
        font-size: clamp(1.4rem, 4vw, 2.5rem); /* Match the font size dynamically */
        font-weight: bold;
        flex-shrink: 0; /* Ensure the grade doesn't shrink */
        margin-left: 10px; /* Add some spacing between name and grade */
    }

    .details-container {
        display: flex;
        flex-direction: row; /* Arrange text and widget in a row */
        justify-content: space-between; /* Push PriceCharting to the right */
        align-items: flex-start; /* Align the top of both containers */
        gap: 10px;
        margin-top: 0; /* Remove excess spacing */
    }

    .card-details {
        line-height: 1.4; /* Adjust line spacing */
        flex: 1; /* Allow card details to take up available space */
    }

    .pricecharting-container {
        flex-shrink: 0;
        display: flex;
        flex-direction: column; /* Stack PriceCharting details */
        align-items: flex-end; /* Align widget to the right */
        margin-top: 0; /* Align top with text container */
    }

    .pricecharting-widget {
        width: auto; /* Allow dynamic sizing */
        background: rgba(245, 245, 245, 1);
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 10px;
        box-sizing: border-box;
        font-size: 0.9rem;
    }

    .checkout {
        width: 100%; /* Spread checkout to full width */
        margin-top: 20px;
        text-align: center;
        padding: 10px 0;
    }

    .listing-image {
        width: 100%; /* Ensure the image container spans the full width */
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 70px; /* Space above the image */
    }

    .listing-image img {
        max-width: 100%; /* Make sure the image fills the container */
        height: auto; /* Maintain aspect ratio */
        border-radius: 25px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
}
