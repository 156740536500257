.binder {
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
    padding: 10px;
    scrollbar-width: thin; /* For Firefox */
    -ms-overflow-style: none; /* For IE and Edge */
}

.binder::-webkit-scrollbar {
    display: none; /* Hide scrollbar for WebKit browsers */
}

.binder-scroll {
    display: flex;
    gap: 10px; /* Space between cards */
    width: auto; /* Allow flexible width for scrolling */
}

.binder .card {
    flex: 0 0 auto; /* Prevent cards from stretching */
    width: 200px; /* Adjust card width as needed */
}
