.search-bar {
    display: flex;
    justify-content: center; /* Center the search bar */
    align-items: center; /* Align items vertically */
    max-width: 600px;
    width: 100%; /* Take full width when needed */
}

.input-container {
    position: relative; /* Positioning context for the button */
    width: 100%; /* Ensure it spans the full width of the search bar */
}

.input-container input {
    width: 100%;
    padding: 10px 50px 10px 15px; /* Add padding for the button space */
    height: 40px; /* Match the button height */
    border-radius: 40px; /* Rounded corners for the input */
    font-size: 16px;
    box-sizing: border-box; /* Ensure padding doesn’t overflow */
}

.input-container .search-button {
    position: absolute; /* Position inside the input field */
    top: 50%; /* Center vertically */
    right: 10px; /* Position near the right inside the input */
    transform: translateY(-50%);
    background-color: #F58092; /* White circular button */
    color: #ffffff; /* Pink color for the '>' symbol */
    font-size: 18px; /* Font size for the '>' */
    cursor: pointer;
    width: 30px; /* Circular size */
    height: 30px; /* Circular size */
    border-radius: 50%; /* Make it a circle */
    display: flex; /* Center the content inside */
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    text-decoration: none; /* Ensure no underline appears */
}

.input-container .search-button:hover {
    background-color: #ffffff; /* Change background color on hover */
    color: #F58092; /* Change text color to pink on hover */
    text-decoration: none; /* Prevent underline on hover */
}


@media (max-width: 625px) {
    .search-bar {
        display: none; /* Hide the search bar on smaller screens */
    }
}
