.energy-symbols {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.energy-icon {
    width: 40px;
    height: 40px;
    margin: 0 10px;
    cursor: pointer;
}
