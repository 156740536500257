.card {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    text-align: center;
    transition: transform 0.2s;
    margin: auto;
    border-radius: 10px;
}

.card-image {
    width: 100%;
    height: auto;
    border-radius: 10px; /* Ensure the image conforms to the card shape */
}
.card-details {
    display: flex;
    justify-content: center;
    padding: 10px;
    font-family: 'Arial', sans-serif;
    color: #333;
}

.card-info {
    display: flex; /* Ensure elements are aligned horizontally */
    justify-content: space-between; /* Align name to the left and price to the right */
    align-items: center; /* Vertically align name and price */
    width: 100%; /* Ensure it spans the container */
}

.card-name {
    font-size: rem;
    font-weight: bold;
    overflow: hidden; /* Prevent overflow if the name is too long */
    text-overflow: ellipsis; /* Add ellipsis for truncated names */
    white-space: nowrap; /* Prevent wrapping */
    flex: 1; /* Take up available space */
    text-align: left;
}

.card-price {
    font-size: 1rem;
    font-weight: bold;
    white-space: nowrap; /* Prevent wrapping */
    text-align: right;
    color: #333;
    flex-shrink: 0; /* Ensure the price does not shrink */
}


/* Media query for phones only */
@media (max-width: 600px) {
    .card {
        width: 90%; /* Slightly wider for smaller screens */
    }

    .card-image {
        height: auto;
        width: 100%; /* Adjust image to fit card width */
    }

    .card-details {
        flex-direction: row; /* Ensure name and price remain side by side */
        justify-content: space-between; /* Name on left, price on right */
        align-items: center; /* Vertically align the text */
    }

    .card-name, .card-price {
        font-size: 0.9rem; /* Slightly smaller text for smaller screens */
    }
}
