.listings-page {
    padding: 0px 20px 20px; /* Adjust padding if necessary */
    text-align: center;
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

@media (max-width: 600px) {
    .listings-page {
        padding: 0 10px; /* Reduce padding for smaller screens */
    }
}
